import React from 'react';
import { AlertTriangle } from 'lucide-react';

const NotFound = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <div className="mb-6 animate-bounce">
          <AlertTriangle className="mx-auto h-16 w-16 text-yellow-500" />
        </div>
        <h1 className="text-4xl font-bold text-gray-800 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-600 mb-4">Page Not Found</h2>
        <p className="text-gray-500 mb-8">
          Oops! The page you're looking for doesn't exist or has been moved.
        </p>
        <a
          href="/"
          className="inline-block bg-[#2E6C83] hover:bg-[#6CE5E8] text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Go Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;