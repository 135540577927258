import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../firebase';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [newData, setNewData] = useState({ displayName: '', email: '', weight: '', goal: '', phoneNumber: '' });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await db.collection('users').doc(user.uid).get();
          if (userDoc.exists) {
            const data = userDoc.data();
            setUserData(data);
            setNewData(data);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };

  const handleImageUpload = async () => {
    if (profileImage) {
      try {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(`profile_pictures/${auth.currentUser.uid}`);
        await fileRef.put(profileImage);
        const photoURL = await fileRef.getDownloadURL();
        await auth.currentUser.updateProfile({ photoURL });
        await db.collection('users').doc(auth.currentUser.uid).update({ photoURL });
        alert('Profile picture updated!');
      } catch (error) {
        console.error('Error updating profile picture:', error);
        setError('Failed to upload profile picture.');
      }
    }
  };

  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await user.updateProfile({ displayName: newData.displayName });
        await user.updateEmail(newData.email);
        await db.collection('users').doc(user.uid).update(newData);

        // Update password if entered and confirmed
        if (password && password === confirmPassword) {
          await user.updatePassword(password);
          alert('Password updated successfully!');
        } else if (password !== confirmPassword) {
          throw new Error('Passwords do not match.');
        }

        // Update phone number if entered
        if (newData.phoneNumber) {
          await user.updatePhoneNumber(newData.phoneNumber);
        }

        alert('Profile updated!');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>No user data found.</div>;
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-200">
      <br />
      <div className="bg-gray-100 p-6 rounded shadow-lg w-96">
        <h2 className="text-2xl font-bold text-[#2E6C83]">Edit Profile</h2>

        {/* Display error if any */}
        {error && <div className="text-red-500 mb-4">{error}</div>}

        {/* Profile Picture Upload */}
        <div className="mb-4">
          <label className="block text-gray-700">Profile Picture</label>
          <input type="file" onChange={handleImageChange} className="w-full p-2 border rounded mb-2" />
          <button onClick={handleImageUpload} className="bg-[#2E6C83] text-white p-2 rounded w-full">Upload Picture</button>
        </div>

        {/* Display Name */}
        <div className="mb-4">
          <label className="block text-gray-700">Display Name</label>
          <input
            type="text"
            value={newData.displayName}
            onChange={(e) => setNewData({ ...newData, displayName: e.target.value })}
            className="w-full p-2 border rounded"
            maxLength={30}
            placeholder="Enter your display name"
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            value={newData.email}
            onChange={(e) => setNewData({ ...newData, email: e.target.value })}
            className="w-full p-2 border rounded"
            maxLength={50}
            placeholder="Enter your email"
          />
        </div>

        {/* Password */}
        <div className="mb-4">
          <label className="block text-gray-700">New Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter new password"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Confirm new password"
          />
        </div>

        {/* Phone Number */}
        <div className="mb-4">
          <label className="block text-gray-700">Phone Number</label>
          <input
            type="tel"
            value={newData.phoneNumber}
            onChange={(e) => setNewData({ ...newData, phoneNumber: e.target.value })}
            className="w-full p-2 border rounded"
            maxLength={15}
            placeholder="Enter your phone number"
          />
        </div>

        {/* Weight */}
        <div className="mb-4">
          <label className="block text-gray-700">Age</label>
          <input
            type="text"
            value={newData.age}
            onChange={(e) => setNewData({ ...newData, age: e.target.value })}
            className="w-full p-2 border rounded"
            maxLength={3}
            placeholder="Enter your age"
          />
        </div>

        {/* Goal */}
        <div className="mb-4">
          <label className="block text-gray-700">Goal</label>
          <input
            type="text"
            value={newData.goal}
            onChange={(e) => setNewData({ ...newData, goal: e.target.value })}
            className="w-full p-2 border rounded"
            maxLength={50}
            placeholder="Enter your goal. e.g To get fit"
          />
        </div>

        <button onClick={handleSave} className="bg-[#2E6C83] text-white p-2 rounded w-full">Save Changes</button>
      </div>
    </div>
  );
};

export default Profile;
