import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // For password confirmation
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const storedUID = localStorage.getItem('userUID');
        if (storedUID) {
          setAllowed(true);
          setLoading(false);
          return;
        }

        const userIP = await fetch('https://api.ipify.org?format=json')
          .then(res => res.json())
          .then(data => data.ip);

        const ipDoc = await db.collection('loggedIPs').doc(userIP).get();
        if (ipDoc.exists) {
          setAllowed(true);
          setLoading(false);
          return;
        }

        const urlParams = new URLSearchParams(location.search);
        const sessionTag = urlParams.get('session');

        if (!sessionTag) {
          alert('Access denied, Please purchase a plan or contact support.');
          navigate('/error');
          return;
        }

        const purchaseQuery = db.collection('purchases').where('sessionTag', '==', sessionTag);
        const purchaseSnapshot = await purchaseQuery.get();

        if (purchaseSnapshot.empty) {
          alert('Invalid session tag. Please contact support.');
          navigate('/error');
          return;
        }

        const purchaseDoc = purchaseSnapshot.docs[0];
        const purchaseData = purchaseDoc.data();

        if (purchaseData.usesLeft <= 0) {
          alert('This link has expired.');
          navigate('/error');
          return;
        }

        await purchaseDoc.ref.update({ usesLeft: purchaseData.usesLeft - 1 });
        await db.collection('loggedIPs').doc(userIP).set({ sessionTag, timestamp: Date.now() });

        setAllowed(true);
        setLoading(false);
      } catch (error) {
        console.error('Error in access validation:', error);
        setError('An error occurred during access validation. Please try again.');
        navigate('/error');
      }
    };

    checkAccess();

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        navigate('/');
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [location.search, navigate]);

  const handleEmailValidation = async () => {
    try {
      const purchaseQuery = await db.collection('purchases').where('email', '==', email).get();
      if (purchaseQuery.empty) {
        setError('Email not found. Please contact support or Use the same email you entered when purchasing your plan.');
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error during email validation:', error);
      setError('Error checking email. Please contact support.');
      return false;
    }
  };

  const handleEmailAuth = async () => {
    setError(null);

    // Check email in purchases collection for validation
    const isEmailValid = await handleEmailValidation();
    if (!isEmailValid) return;

    // Password confirmation check (for sign-up)
    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      if (isLogin) {
        const userCredential = await auth.signInWithEmailAndPassword(email, password);
        localStorage.setItem('userUID', userCredential.user.uid);
      } else {
        const userCredential = await auth.createUserWithEmailAndPassword(email, password);
        await userCredential.user.sendEmailVerification();
        alert('A verification link has been sent to your email.');
        navigate('/');

        const sessionTag = new URLSearchParams(location.search).get('session');
        const userIP = await fetch('https://api.ipify.org?format=json').then(res => res.json()).then(data => data.ip);

        await db.collection('users-auth').doc(userCredential.user.uid).set({
          email: userCredential.user.email,
          uid: userCredential.user.uid,
          sessionTag: sessionTag,
          ipAddress: userIP,
          createdAt: new Date(),
        });

        await db.collection('users').doc(userCredential.user.uid).set({
          email: userCredential.user.email,
          uid: userCredential.user.uid,
          sessionTag: sessionTag,
          ipAddress: userIP,
          createdAt: new Date(),
        });

        localStorage.setItem('userUID', userCredential.user.uid);
        navigate('/onboarding');
      }
    } catch (error) {
      console.error('Authentication error:', error);
      setError(error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!allowed) return <div>Loading...</div>;

  return (
    <div className="flex items-center justify-center w-screen h-screen p-8 bg-gray-100">
      <div className="bg-white p-8 rounded shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-4 text-center">{isLogin ? 'Login' : 'Sign Up'}</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full p-2 mb-4 border rounded"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="w-full p-2 mb-4 border rounded"
        />

        {!isLogin && (
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            className="w-full p-2 mb-4 border rounded"
          />
        )}

        <button onClick={handleEmailAuth} className="bg-[#2E6C83] text-white p-2 rounded w-full">
          {isLogin ? 'Login' : 'Sign Up'}
        </button>

        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}

        <p className="mt-4 text-center">
          {isLogin ? "Don't have an account?" : 'Already have an account?'}
          <span onClick={() => setIsLogin(!isLogin)} className="text-blue-500 cursor-pointer ml-2">
            {isLogin ? 'Sign Up' : 'Login'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Auth;
