import React from 'react';

const Mealplanner = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      {/* Main Section */}
      <header className="text-center py-10">
        <h1 className="text-3xl font-bold text-[#2E6C83]">Generate a custom <strong>Meal Planner</strong></h1>
        <p className="text-lg mt-2 text-gray-600">Optionally, you can use our ChatBot <a href="/nutribot" className="text-[#2E6C83] hover:underline">Here</a></p>
      </header>

      {/* NutriBot Embed Section */}
      <section className="w-full max-w-5xl bg-white shadow-lg rounded-lg p-6">
        <iframe
          id="nutribot-frame"
          src="https://meal-planner.nutriplan.seif8911.xyz/"
          frameBorder="0"
          width="100%"
          height="500px"
          title="NutriBot AI Assistant"
          className="rounded-lg"
        ></iframe>
      </section>
    </div>
  );
};

export default Mealplanner;
