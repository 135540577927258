import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const Onboarding = () => {
  const [userData, setUserData] = useState({
    displayName: '',
    height: '',
    weight: '',
    goal: '',
    optionalField1: '',
    gender: '',
    age: '',
  });
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 100); // Small delay to trigger animation
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    await user.updateProfile({
      displayName: userData.displayName,
    });
    await db.collection('users').doc(user.uid).set(userData);
    navigate('/');
  };

  const handleSkip = () => {
    navigate('/');
  };

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded shadow-lg w-96 transform transition-transform duration-500 ease-in-out scale-110 hover:scale-100">
        <h2 className="text-2xl font-bold mb-4">Welcome to NutriPlan!</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Display Name</label>
          <input
            type="text"
            value={userData.displayName}
            onChange={(e) => setUserData({ ...userData, displayName: e.target.value })}
            className="w-full p-2 border rounded"
            placeholder="What's Your Name?"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Height (cm)</label>
          <input
            type="number"
            value={userData.height}
            onChange={(e) => setUserData({ ...userData, height: e.target.value })}
            className="w-full p-2 border rounded"
            placeholder="What's Your Height?"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Weight (kg)</label>
          <input
            type="number"
            value={userData.weight}
            onChange={(e) => setUserData({ ...userData, weight: e.target.value })}
            className="w-full p-2 border rounded"
            placeholder="What's Your Weight?"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Age</label>
          <input
            type="number"
            value={userData.age}
            onChange={(e) => setUserData({ ...userData, age: e.target.value })}
            className="w-full p-2 border rounded"
            placeholder="What's Your Age?"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Goal</label>
          <input
            type="text"
            value={userData.goal}
            onChange={(e) => setUserData({ ...userData, goal: e.target.value })}
            className="w-full p-2 border rounded"
            placeholder="What's Your Goal. e.g To get Fit."
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">From Where did you hear about us?</label>
          <input
            type="text"
            value={userData.optionalField1}
            onChange={(e) => setUserData({ ...userData, optionalField1: e.target.value })}
            className="w-full p-2 border rounded"
            placeholder="e.g Facebook, Instagram, Youtube, Tiktok"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Gender</label>
          <select
            value={userData.gender}
            onChange={(e) => setUserData({ ...userData, gender: e.target.value })}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <button type="submit" className="bg-[#2E6C83] text-white p-2 rounded w-full">Complete Onboarding</button>
        <button type="button" onClick={handleSkip} className="mt-4 text-blue-500 hover:underline w-full">Skip</button>
      </form>
    </div>
  );
};

export default Onboarding;