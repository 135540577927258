import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { db, auth } from '../firebase';

// Register the required scales
Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

const ProgressChart = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const fetchProgressData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const progressDocs = await db.collection('healthHistory')
            .where('userId', '==', user.uid)
            .orderBy('date', 'asc')
            .get();

          const dates = [];
          const weights = [];
          progressDocs.forEach(doc => {
            const data = doc.data();
            dates.push(data.date);
            weights.push(data.weight);
          });

          setChartData({
            labels: dates,
            datasets: [
              {
                label: 'Weight Over Time',
                data: weights,
                fill: false,
                backgroundColor: '#2E6C83',
                borderColor: '#2E6C83',
              },
            ],
          });
        } catch (error) {
          console.error('Error fetching progress data:', error);
        }
      }
    };

    fetchProgressData();
  }, []);

  return (
    <div className="bg-white p-4 rounded-lg shadow mb-6 md:mb-0">
      <h3 className="text-xl mb-4 text-[#2E6C83]">Progress Chart</h3>
      <Line data={chartData} />
    </div>
  );
};

export default ProgressChart;
