import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import 'bootstrap/dist/css/bootstrap.min.css';

const Navbar = ({ isLoggedIn, handleSignOut }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-[#2E6C83] d-md-none">
      <Link className="navbar-brand d-flex align-items-center" to="/">
        <img src="https://i.ibb.co/TtJdfS5/image.png" alt="NutriPlan Logo" width="140" className="mb-2" />
        <h2 className="text-xl font-bold">NUTRIPLAN</h2>
      </Link>

      <button
        className="navbar-toggler ms-auto"
        type="button"
        onClick={toggleNavbar}
        aria-controls="navbarNav"
        aria-expanded={isNavOpen}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <CSSTransition
        in={isNavOpen || !isMobile}
        timeout={300}
        classNames="nav-animation"
        unmountOnExit
      >
        <div className="navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link text-center" to="/" onClick={() => setIsNavOpen(false)}>
                DASHBOARD
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-center" to="/yourplan" onClick={() => setIsNavOpen(false)}>
                YOUR PLAN
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-center" to="/trackers" onClick={() => setIsNavOpen(false)}>
                TRACKERS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-center" to="/myhealth" onClick={() => setIsNavOpen(false)}>
                MY HEALTH
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-center" to="/profile" onClick={() => setIsNavOpen(false)}>
                My Profile
              </Link>
              </li>
            <li className="nav-item">
              <Link className="nav-link text-center" to="/center" onClick={() => setIsNavOpen(false)}>
                Ai - Center
              </Link>
            </li>
            <li className="nav-item d-md-none text-center">
              <button
                className="nav-link text-center bg-transparent border-0 w-100"
                onClick={handleSignOut}
                style={{ textAlign: 'center' }}
              >
                <span className="me-2">→</span> {isLoggedIn ? 'SIGN OUT' : 'LOG IN'}
              </button>
            </li>
          </ul>
        </div>
      </CSSTransition>
    </nav>
  );
};

const Sidebar = ({ isLoggedIn, handleSignOut }) => {
  useEffect(() => {
    const sidebar = document.querySelector('.sidebar');
    sidebar.classList.add('animate__animated', 'animate__fadeInLeft');

    return () => {
      sidebar.classList.remove('animate__animated', 'animate__fadeInLeft');
    };
  }, []);

  return (
    <div className="sidebar d-none d-md-flex flex-column h-100 fixed-left w-48 bg-[#2E6C83] text-white p-4">
      <div className="mb-8">
        <img src="https://i.ibb.co/TtJdfS5/image.png" alt="NutriPlan Logo" className="mb-2" />
        <h2 className="text-xl font-bold">NUTRIPLAN</h2>
        <p className="text-xs">WHERE MEALS MEETS TASTE</p>
      </div>
      <nav className="flex-grow">
        <ul className="space-y-5">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "p-2 rounded bg-[#6CE5E8] text-[#2E6C83]" : "p-2 rounded hover:bg-[#6CE5E8]"
              }
            >
              DASHBOARD
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/yourplan"
              className={({ isActive }) =>
                isActive ? "p-2 rounded bg-[#6CE5E8] text-[#2E6C83]" : "p-2 rounded hover:bg-[#6CE5E8]"
              }
            >
              YOUR PLAN
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/trackers"
              className={({ isActive }) =>
                isActive ? "p-2 rounded bg-[#6CE5E8] text-[#2E6C83]" : "p-2 rounded hover:bg-[#6CE5E8]"
              }
            >
              TRACKERS
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/myhealth"
              className={({ isActive }) =>
                isActive ? "p-2 rounded bg-[#6CE5E8] text-[#2E6C83]" : "p-2 rounded hover:bg-[#6CE5E8]"
              }
            >
              MY HEALTH
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? "p-2 rounded bg-[#6CE5E8] text-[#2E6C83]" : "p-2 rounded hover:bg-[#6CE5E8]"
              }
            >
              My Profile
            </NavLink>
            <br></br>
            <br></br>
            <NavLink
              to="/center"
              className={({ isActive }) =>
                isActive ? "p-2 rounded bg-[#6CE5E8] text-[#2E6C83]" : "p-2 rounded hover:bg-[#6CE5E8]"
              }
            >
              AI-Center
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="mt-auto">
        <button className="text-sm flex items-center" onClick={handleSignOut}>
          <span className="mr-2">→</span> {isLoggedIn ? 'SIGN OUT' : 'LOG IN'}
        </button>
      </div>
    </div>
  );
};

const MainSidebar = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    if (isLoggedIn) {
      auth.signOut().then(() => {
        setIsLoggedIn(false);
        navigate('/login');
      });
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <Sidebar isLoggedIn={isLoggedIn} handleSignOut={handleSignOut} />
      <Navbar isLoggedIn={isLoggedIn} handleSignOut={handleSignOut} />
    </>
  );
};

export default MainSidebar;