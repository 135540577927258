import React from 'react';
// import { db, auth } from '../firebase';

const Error = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen p-8 bg-gray-100">
      <div className="p-8 bg-white shadow rounded">
        <h2 className="text-2xl font-bold mb-4">Error</h2>
    
          <div>
            <h3 className="text-lg font-semibold">Your Request was invalid, Or the page couldn't be found.</h3>
            <p>If the issue persists contact <a href="mailto:support-nutriplan@seif8911.xyz">Support</a></p>
            <p className="mt-2"></p>
            <button className="mt-4 bg-[#2E6C83] text-white p-2 rounded">
            Home
            </button>
          </div>
      </div>
    </div>
  );
};

export default Error;
