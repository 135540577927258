import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#2E6C83] text-white py-6 w-full mt-auto">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        {/* Logo and Copyright */}
        <div className="text-center md:text-left mb-4 md:mb-0">
          <img src="https://i.ibb.co/TtJdfS5/image.png" alt="NutriPlan Logo" className="mb-2" />
          <NavLink to="/" className="text-white text-2xl font-bold mb-2">
            Nutriplan
          </NavLink>
          <p className="mt-2 text-sm">&copy; 2024 Nutriplan. All rights reserved.</p>
        </div>

        {/* Links Section */}
        <div className="flex flex-wrap justify-center md:justify-start space-x-4 md:space-x-6">
          <NavLink to="/" className="hover:underline text-sm">Home</NavLink>
          <NavLink to="/trackers" className="hover:underline text-sm">Trackers</NavLink>
          <NavLink to="/yourplan" className="hover:underline text-sm">Plans</NavLink>
          <NavLink to="/myhealth" className="hover:underline text-sm">My Health</NavLink>
          <NavLink to="/profile" className="hover:underline text-sm">Profile</NavLink>
          <NavLink to="/center" className="hover:underline text-sm">Ai - Center</NavLink>
        </div>

        {/* Developer Info */}
        <div className="text-center md:text-right mt-4 md:mt-0">
          <p className="text-sm">Developed by <a href="https://seif8911.xyz" className="hover:underline">Web-Design Artists</a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;