import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect'; // To detect mobile devices

const NutriBot = () => {
  useEffect(() => {
    // Check if the user is on a mobile device
    if (isMobile) {
      alert("NutriBot is not accessible on mobile devices. Please use a desktop browser.");
      window.location.href = "/"; // Redirect to homepage or any other page
    } else {
      alert("Warning: NutriBot is still in development and may occasionally generate false information. This is not a replacement for doctors or nutritionists. If you're feeling unwell, seek medical help.");
    }
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      {/* Main Section */}
      <header className="text-center py-10">
        <h1 className="text-3xl font-bold text-[#2E6C83]">Chat with our <strong>NutriBot</strong></h1>
        <p className="text-lg mt-2 text-gray-600">Optionally, you can use our AI Meal Planner <a href="http://demo1.seif8911.xyz" className="text-[#2E6C83] hover:underline">Here</a></p>
      </header>

      {/* NutriBot Embed Section */}
      <section className="w-full max-w-5xl bg-white shadow-lg rounded-lg p-6">
        <iframe
          id="nutribot-frame"
          src="https://nutribot.seif8911.xyz"
          frameBorder="0"
          width="100%"
          height="500px"
          title="NutriBot AI Assistant"
          className="rounded-lg"
        ></iframe>
      </section>
    </div>
  );
};

export default NutriBot;
