import React, { useState } from 'react';

const Tracker = ({ title, description, placeholder, onSubmit, imageSrc }) => {
  const [inputValue, setInputValue] = useState('');
  const [outputMessage, setOutputMessage] = useState('');

  const handleSubmit = () => {
    const responseText = onSubmit(inputValue);
    setOutputMessage(responseText);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 m-2 flex-none w-80">
      <div className="flex flex-col items-center">
        <div className="text-center mb-4">
          <h5 className="text-xl font-semibold">{title}</h5>
          <p className="text-gray-600">{description}</p>
        </div>
        <input
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="form-input w-full mb-4 p-2 border rounded"
          placeholder={placeholder}
        />
        <button
          className="w-full mb-3 py-2 px-4 rounded bg-[#2E6C83] text-white"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <div className="text-gray-700 mb-3" dangerouslySetInnerHTML={{ __html: outputMessage }} />
        <img src={imageSrc} className="w-full h-auto rounded-lg" alt={title} />
      </div>
    </div>
  );
};

const Trackers = () => {
  const [activeTab, setActiveTab] = useState('exercise');

  const handleExerciseSubmit = (inputValue) => {
    if (inputValue > 0) {
      return `Great job on working out for ${inputValue} minutes today! Keep it up!`;
    } else {
      return `You didn't log any workout minutes today. Try to incorporate some exercise into your routine.`;
    }
  };

  const handleStepsSubmit = (inputValue) => {
    if (inputValue < 5000) {
      return `You took ${inputValue} steps today. Try to reach at least 10,000 steps for better fitness.`;
    } else {
      return `Great job on taking ${inputValue} steps today! Keep moving to stay fit.`;
    }
  };

  const handleWorkoutSubmit = (inputValue) => {
    if (inputValue < 60) {
      return `You worked out ${inputValue} minutes today. Try to reach at least 1 hour of workout for better fitness.`;
    } else {
      return `Great job on working out for ${inputValue} minutes today! Keep working out to stay fit.`;
    }
  };

  const handleSleepSubmit = (inputValue) => {
    if (inputValue < 5) {
      return `You slept ${inputValue} hours today. Try to sleep for at least 6 hours for better mental health and fitness.`;
    } else {
      return `Great job on sleeping for ${inputValue} hours today! Your body has definitely benefited from that.`;
    }
  };

  const handleCalorieSubmit = (inputValue) => {
    if (inputValue < 2000) {
      return `You ate ${inputValue} calories today. Try to eat 2,200–3,000 calories for better activity, health, and fitness.`;
    } else {
      return `Great job on eating ${inputValue} calories today! Your body has definitely benefited from that.`;
    }
  };

  const handleWaterSubmit = (inputValue) => {
    if (inputValue < 8) {
      return `You drank ${inputValue} glasses of water today. Try to drink at least 8 glasses for better activity, health, and fitness.`;
    } else {
      return `Great job on drinking ${inputValue} glasses of water today! Your body has definitely benefited from that.`;
    }
  };

  const handleCigarettesSubmit = (inputValue) => {
    if (inputValue > 0) {
      return `You smoked ${inputValue} cigarette(s) today. Consider cutting down to improve your lung health.`;
    } else {
      return `Well done on not smoking any cigarettes today!`;
    }
  };

  const handleVapesSubmit = (inputValue) => {
    if (inputValue > 0) {
      return `You vaped ${inputValue} time(s) today. Reducing this can help avoid potential health risks.`;
    } else {
      return `Great job on not vaping today!`;
    }
  };

  const handleStressSubmit = (inputValue) => {
    if (inputValue > 5) {
      return `Your stress level is at ${inputValue}. Consider practicing mindfulness or other relaxation techniques.`;
    } else {
      return `Your stress level is at ${inputValue}. Keep managing your stress effectively.`;
    }
  };

  const handleMindfulnessSubmit = (inputValue) => {
    if (inputValue > 0) {
      return `Well done on practicing mindfulness for ${inputValue} minutes today.`;
    } else {
      return `Try to practice mindfulness today to help manage your stress.`;
    }
  };

  return (
    <div className="flex-1 p-8 overflow-auto bg-gray-200 w-screen">
      <h1 className="text-2xl font-bold text-[#2E6C83] mb-8">Your Trackers</h1>

      <div className="tabs">
        <ul className="flex space-x-4 border-b border-gray-200 mb-6">
          <li className="flex-1">
            <button
              className={`py-2 px-4 block ${activeTab === 'exercise' ? 'text-[#2E6C83] border-b-2 border-[#2E6C83]' : 'text-gray-600 hover:text-[#2E6C83]'}`}
              onClick={() => setActiveTab('exercise')}
            >
              Exercise
            </button>
          </li>
          <li className="flex-1">
            <button
              className={`py-2 px-4 block ${activeTab === 'sleep' ? 'text-[#2E6C83] border-b-2 border-[#2E6C83]' : 'text-gray-600 hover:text-[#2E6C83]'}`}
              onClick={() => setActiveTab('sleep')}
            >
              Sleep
            </button>
          </li>
          <li className="flex-1">
            <button
              className={`py-2 px-4 block ${activeTab === 'nutrition' ? 'text-[#2E6C83] border-b-2 border-[#2E6C83]' : 'text-gray-600 hover:text-[#2E6C83]'}`}
              onClick={() => setActiveTab('nutrition')}
            >
              Nutrition
            </button>
          </li>
          <li className="flex-1">
            <button
              className={`py-2 px-4 block ${activeTab === 'smoking' ? 'text-[#2E6C83] border-b-2 border-[#2E6C83]' : 'text-gray-600 hover:text-[#2E6C83]'}`}
              onClick={() => setActiveTab('smoking')}
            >
              Smoking
            </button>
          </li>
          <li className="flex-1">
            <button
              className={`py-2 px-4 block ${activeTab === 'stress' ? 'text-[#2E6C83] border-b-2 border-[#2E6C83]' : 'text-gray-600 hover:text-[#2E6C83]'}`}
              onClick={() => setActiveTab('stress')}
            >
              Stress
            </button>
          </li>
        </ul>

        <div className="tab-content">
          {activeTab === 'exercise' && (
            <div className="flex overflow-x-auto space-x-4">
              <Tracker
                title="Daily Exercise"
                description="How many minutes did you exercise today?"
                placeholder="Enter minutes"
                onSubmit={handleExerciseSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/exercise.PNG"
              />
              <Tracker
                title="Daily Steps"
                description="What is the total number of steps you took today?"
                placeholder="Enter steps"
                onSubmit={handleStepsSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/steps.PNG"
              />
              <Tracker
                title="Workout Time"
                description="How many minutes did you work out today?"
                placeholder="Enter minutes"
                onSubmit={handleWorkoutSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/work.PNG"
              />
            </div>
          )}

          {activeTab === 'sleep' && (
            <div className="flex overflow-x-auto space-x-4">
              <Tracker
                title="Sleep Tracker"
                description="How many hours did you sleep last night?"
                placeholder="Enter Hours"
                onSubmit={handleSleepSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/sleep.PNG"
              />
            </div>
          )}

          {activeTab === 'nutrition' && (
            <div className="flex overflow-x-auto space-x-4">
              <Tracker
                title="Calorie Intake"
                description="How many calories did you consume today?"
                placeholder="Enter amount"
                onSubmit={handleCalorieSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/calorie intake.PNG"
              />
              <Tracker
                title="Water Intake"
                description="How many glasses of water did you drink today?"
                placeholder="Enter amount"
                onSubmit={handleWaterSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/water.PNG"
              />
            </div>
          )}

          {activeTab === 'smoking' && (
            <div className="flex overflow-x-auto space-x-4">
              <Tracker
                title="Cigarettes"
                description="How many cigarettes did you smoke today?"
                placeholder="Enter number"
                onSubmit={handleCigarettesSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/smoke.PNG"
              />
              <Tracker
                title="Vapes"
                description="How many times did you use the vape today?"
                placeholder="Enter number"
                onSubmit={handleVapesSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/vape.PNG"
              />
            </div>
          )}

          {activeTab === 'stress' && (
            <div className="flex overflow-x-auto space-x-4">
              <Tracker
                title="Stress Level"
                description="How stressed are you today on a scale of 1 to 10?"
                placeholder="Enter stress level"
                onSubmit={handleStressSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/stress.PNG"
              />
              <Tracker
                title="Mindfulness Practice"
                description="How many minutes did you practice mindfulness today?"
                placeholder="Enter minutes"
                onSubmit={handleMindfulnessSubmit}
                imageSrc="https://nutriplan.seif8911.xyz/images/mindfulness.PNG"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Trackers;
