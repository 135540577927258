import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from '../firebase';
import ProgressChart from './ProgressChart';
import { onSnapshot } from 'firebase/firestore';
import Joyride, { STATUS } from 'react-joyride';
import { NavLink } from 'react-router-dom';

const calculateRecommendedCalories = (bmi) => {
  if (bmi < 18.5) return '2200-2500 kcal (underweight)';
  if (bmi >= 18.5 && bmi < 25) return '2000-2500 kcal (normal weight)';
  if (bmi >= 25 && bmi < 30) return '1500-2000 kcal (overweight)';
  return '1200-1500 kcal (obesity)';
};

const Dashboard = () => {
  const [userData, setUserData] = useState({});
  const [healthData, setHealthData] = useState({
    weight: '',
    height: '',
    steps: '',
    calories: '',
    hydration: '',
    exerciseMinutes: '',
    dailyCalorieGoal: '',
    dailyStepGoal: '',
    dailyHydrationGoal: '',
    dailyExerciseGoal: '',
  });
  const [currentDate, setCurrentDate] = useState('');
  const [runTutorial, setRunTutorial] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const fetchUserData = useCallback(async () => {
    const userDoc = await db.collection('users').doc(auth.currentUser.uid).get();
    setUserData(userDoc.data() || {});
  }, []);

  useEffect(() => {
    fetchUserData();

    const healthUnsubscribe = onSnapshot(
      db.collection('health').doc(auth.currentUser.uid),
      (doc) => {
        if (doc.exists) {
          setHealthData(doc.data() || {});
        }
      },
      (error) => {
        console.error("Error fetching health data:", error);
      }
    );

    const today = new Date();
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    setCurrentDate(today.toLocaleDateString(undefined, options));

    // Check if the user has seen the tutorial
    const hasSeenTutorial = localStorage.getItem('hasSeenDashboardTutorial');
    if (!hasSeenTutorial) {
      setRunTutorial(true);
    }

    return () => {
      healthUnsubscribe();
    };
  }, [fetchUserData]);

  const calculateBMI = (weight, height) => {
    if (weight && height) {
      const heightInMeters = height / 100;
      return (weight / (heightInMeters ** 2)).toFixed(2);
    }
    return 'N/A';
  };

  const caloriesToBurn = (goal, current) => {
    const goalNum = parseFloat(goal);
    const currentNum = parseFloat(current);
    return !isNaN(goalNum) && !isNaN(currentNum) && goalNum > currentNum ? (goalNum - currentNum).toFixed(2) : 0;
  };
  
  const exerciseToReachGoal = (goal, minutes) => {
    const goalNum = parseFloat(goal);
    const minutesNum = parseFloat(minutes);
    return !isNaN(goalNum) && !isNaN(minutesNum) && goalNum > minutesNum ? (goalNum - minutesNum).toFixed(0) : 0;
  };

  const calculateBMR = (weight, height, age, gender) => {
    if (weight && height && age && gender) {
      const weightNum = parseFloat(weight);
      const heightNum = parseFloat(height);
      const ageNum = parseFloat(age);
      if (gender === 'male') {
        return (88.362 + (13.397 * weightNum) + (4.799 * heightNum) - (5.677 * ageNum)).toFixed(2);
      } else {
        return (447.593 + (9.247 * weightNum) + (3.098 * heightNum) - (4.330 * ageNum)).toFixed(2);
      }
    }
    return 'N/A';
  };

  const calculateBodyFatPercentage = (bmi, age, gender) => {
    if (bmi !== 'N/A' && age && gender) {
      const bmiNum = parseFloat(bmi);
      const ageNum = parseFloat(age);
      if (gender === 'male') {
        return ((1.20 * bmiNum) + (0.23 * ageNum) - 16.2).toFixed(2);
      } else {
        return ((1.20 * bmiNum) + (0.23 * ageNum) - 5.4).toFixed(2);
      }
    }
    return 'N/A';
  };

  const calculateIdealWeight = (height, gender) => {
    if (height && gender) {
      const heightNum = parseFloat(height);
      const heightInInches = heightNum / 2.54;
      if (gender === 'male') {
        return (50 + (2.3 * (heightInInches - 60))).toFixed(2);
      } else {
        return (45.5 + (2.3 * (heightInInches - 60))).toFixed(2);
      }
    }
    return 'N/A';
  };

  const calculateWaterIntake = (weight) => {
    return weight ? (parseFloat(weight) * 0.033).toFixed(2) : 'N/A';
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTutorial(false);
      localStorage.setItem('hasSeenDashboardTutorial', 'true');
      
      // Show the popup after 1 second delay
      setTimeout(() => {
        setShowPopup(true);
      }, 1000);
    }
  };

  const steps = [
    {
      target: '.bmi-card',
      content: 'This card shows your Body Mass Index (BMI) and recommended calorie intake based on your BMI.',
      placement: 'bottom',
    },
    {
      target: '.steps-card',
      content: 'Track your daily steps here. The circle fills up as you reach your daily step goal.',
      placement: 'bottom',
    },
    {
      target: '.calories-card',
      content: 'Monitor your calories burned. The chart shows your progress towards your daily calorie goal.',
      placement: 'bottom',
    },
    {
      target: '.hydration-card',
      content: 'Keep track of your water intake here. Don\'t forget to stay hydrated!',
      placement: 'bottom',
    },
    {
      target: '.bmr-card',
      content: 'Your Basal Metabolic Rate (BMR) is displayed here. This is the number of calories you burn at rest.',
      placement: 'bottom',
    },
    {
      target: '.body-fat-card',
      content: 'This card shows an estimate of your body fat percentage based on your BMI, age, and gender.',
      placement: 'bottom',
    },
    {
      target: '.ideal-weight-card',
      content: 'Your ideal weight based on your height and gender is shown here.',
      placement: 'bottom',
    },
    {
      target: '.exercise-card',
      content: 'Track your exercise minutes here. Try to meet your daily exercise goal!',
      placement: 'bottom',
    },
    {
      target: '.progress-chart',
      content: 'This chart shows your progress over time. Keep up the good work!',
      placement: 'top',
    },
  ];

  const bmi = calculateBMI(healthData.weight, healthData.height);
  const bmr = calculateBMR(healthData.weight, healthData.height, userData.age, userData.gender);
  const bodyFatPercentage = calculateBodyFatPercentage(bmi, userData.age, userData.gender);
  const idealWeight = calculateIdealWeight(healthData.height, userData.gender);

  return (
    <div className="flex-grow p-8 bg-gray-200">
      <Joyride
        steps={steps}
        run={runTutorial}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#2E6C83',
          },
        }}
      />

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-xl font-semibold text-[#2E6C83] mb-4">Update Your Information</h2>
            <p className="text-gray-700 mb-4">
              We strongly recommend visiting the "MyHealth" tab to update your information. This will help
              the dashboard represent your data accurately.
            </p>
            <button
              onClick={() => setShowPopup(false)}
              className="bg-[#2E6C83] text-white p-2 rounded hover:bg-[#1E5C73] transition-colors"
            >
              Got it
            </button>
            <NavLink to="/myhealth">
              <button className="bg-[#2E6C83] text-white p-2 rounded hover:bg-[#1E5C73] transition-colors mt-4 w-full">
                Go to My Health 
              </button>
            </NavLink>
          </div>
        </div>
      )}

      <header className="mb-6 bg-white p-4 rounded-lg shadow">
        <p className="text-gray-500 text-sm">{`Today is ${currentDate}`}</p>
        <h1 className="text-2xl font-bold text-[#2E6C83]">
          {userData?.displayName ? `Hi ${userData.displayName}, How are you today?` : 'Loading...'}
        </h1>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-4 rounded-lg shadow bmi-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">BMI (Body Mass Index)</h3>
          <p className="text-4xl text-center text-[#2E6C83] font-bold">
            {bmi} kg/m²
          </p>
          <p className="text-center mt-2 text-lg">
            {`Recommended Calorie Intake: ${calculateRecommendedCalories(bmi)}`}
          </p>
        </div>

        <div className="bg-white p-4 rounded-lg shadow steps-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">Steps Today</h3>
          <div className="relative w-32 h-32 mx-auto">
            <svg viewBox="0 0 36 36" className="w-full h-full">
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#E6E6E6" strokeWidth="2" />
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#6CE5E8" strokeWidth="2" strokeDasharray={`${(healthData.steps / healthData.dailyStepGoal) * 100 || 0}, 100`} />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <span className="text-2xl font-bold">{healthData.steps || '0'}</span>
              <span className="block text-xs">{`/${healthData.dailyStepGoal || 'N/A'} steps`}</span>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow calories-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">Calories Burned</h3>
          <div className="relative w-32 h-32 mx-auto">
            <svg viewBox="0 0 36 36" className="w-full h-full">
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#E6E6E6" strokeWidth="2" />
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#6CE5E8" strokeWidth="2" strokeDasharray={`${(healthData.calories / healthData.dailyCalorieGoal) * 100 || 0}, 100`} />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <span className="text-2xl font-bold">{healthData.calories || '0'}</span>
              <span className="block text-xs">{`/${healthData.dailyCalorieGoal || 'N/A'} kcal`}</span>
            </div>
          </div>
          <p className="text-center mt-4 text-lg font-semibold text-[#2E6C83]">
            {`You need to burn ${caloriesToBurn(healthData.dailyCalorieGoal, healthData.calories)} more kcal to reach your goal!`}
          </p>
        </div>

        <div className="bg-white p-4 rounded-lg shadow hydration-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">Hydration</h3>
          <div className="relative w-32 h-32 mx-auto">
            <svg viewBox="0 0 36 36" className="w-full h-full">
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#E6E6E6" strokeWidth="2" />
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#6CE5E8" strokeWidth="2" strokeDasharray={`${(healthData.hydration / healthData.dailyHydrationGoal) * 100 || 0}, 100`} />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <span className="text-2xl font-bold">{healthData.hydration || '0'}</span>
              <span className="block text-xs">{`/${healthData.dailyHydrationGoal || 'N/A'} glasses`}</span>
            </div>
          </div>
          <p className="text-center mt-4 text-lg font-semibold text-[#2E6C83]">
            {`Recommended daily water intake: ${calculateWaterIntake(healthData.weight)} liters`}
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow bmr-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">Basal Metabolic Rate (BMR)</h3>
          <p className="text-4xl text-center text-[#2E6C83] font-bold">
            {bmr} kcal/day
          </p>
          <p className="text-center mt-2 text-lg">
            This is the number of calories you burn at rest.
          </p>
        </div>

        <div className="bg-white p-4 rounded-lg shadow body-fat-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">Body Fat Percentage</h3>
          <p className="text-4xl text-center text-[#2E6C83] font-bold">
            {bodyFatPercentage}%
          </p>
          <p className="text-center mt-2 text-lg">
            This is an estimate based on your BMI, age, and gender.
          </p>
        </div>

        <div className="bg-white p-4 rounded-lg shadow ideal-weight-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">Ideal Weight</h3>
          <p className="text-4xl text-center text-[#2E6C83] font-bold">
            {idealWeight} kg
          </p>
          <p className="text-center mt-2 text-lg">
            This is based on your height and gender.
          </p>
        </div>

        <div className="bg-white p-4 rounded-lg shadow exercise-card">
          <h3 className="text-xl mb-4 text-[#2E6C83]">Exercise Minutes</h3>
          <div className="relative w-32 h-32 mx-auto">
            <svg viewBox="0 0 36 36" className="w-full h-full">
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#E6E6E6" strokeWidth="2" />
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke="#6CE5E8" strokeWidth="2" strokeDasharray={`${(healthData.exerciseMinutes / healthData.dailyExerciseGoal) * 100 || 0}, 100`} />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <span className="text-2xl font-bold">{healthData.exerciseMinutes || '0'}</span>
              <span className="block text-xs">{`/${healthData.dailyExerciseGoal || 'N/A'} minutes`}</span>
            </div>
          </div>
          <p className="text-center mt-4 text-lg font-semibold text-[#2E6C83]">
            {`You need ${exerciseToReachGoal(healthData.dailyExerciseGoal, healthData.exerciseMinutes)} more minutes to reach your goal!`}
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-1 gap-1 progress-chart">
          <ProgressChart healthData={healthData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
