import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { useLocation } from 'react-router-dom';
import { onSnapshot } from 'firebase/firestore';

const DEFAULT_AVATAR_URL = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg?20200418092106';

const dailyTips = [
  'Drink at least 8 glasses of water a day!',
  'Take a 10-minute walk after each meal to stay active.',
  'Eat 5 servings of fruits and vegetables daily.',
  'Get at least 7 hours of sleep to recharge.',
  'Stretch for 10 minutes every morning to loosen up.',
  'Never give up on your dreams—keep pushing forward!',
  'Prioritize your mental health—take time for yourself.',
  'Aim for 30 minutes of exercise most days of the week.',
  'Practice gratitude daily to improve your outlook on life.',
  'Choose whole grains over refined ones for better energy.',
  'Set small, achievable goals to keep yourself motivated.',
  'Keep a journal to track your progress and reflect on your journey.',
  'Find a workout buddy to stay accountable and make fitness fun.',
  'Incorporate mindfulness or meditation into your daily routine.',
  'Limit screen time to improve your sleep quality.',
  'Celebrate your successes, no matter how small they seem.',
  'Don’t compare yourself to others—focus on your own progress.',
  'Aim to eat a colorful variety of fruits and vegetables.',
  'Stay hydrated by drinking water before, during, and after meals.',
  'Practice positive self-talk to boost your confidence and motivation.',
  'Take regular breaks during work to avoid burnout.',
  'Enjoy a hobby or activity you love to reduce stress.',
  'Avoid sugary drinks—opt for water or herbal teas instead.',
  'Remember, setbacks are just setups for comebacks.',
  'Listen to your body and give it the rest it needs.',
  'Surround yourself with supportive and encouraging people.',
  'Keep your workspace organized to boost productivity.',
  'Try to include protein in every meal to stay full longer.',
  'Stay consistent with your exercise routine for lasting results.',
  'Practice deep breathing exercises to calm your mind.',
  'Focus on progress, not perfection—every step counts!',
  'Eat mindfully—enjoy and savor each bite.',
  'Find joy in the journey, not just the destination.',
  'Set aside time for relaxation to recharge your batteries.',
  'Take care of your emotional health as much as your physical health.',
  'Make sleep a priority for better overall health.',
  'Learn to say no to things that drain your energy.',
  'Practice kindness to others and yourself—it’s good for the soul.',
  'Aim to walk 10,000 steps a day for better fitness.',
  'Avoid processed foods—opt for fresh and natural ingredients.',
  'Keep a water bottle with you to remind yourself to stay hydrated.',
  'Set a regular sleep schedule to improve your sleep quality.',
  'Embrace challenges as opportunities to grow.',
  'Make healthy snacks like nuts and fruits easily accessible.',
  'Regularly review your goals and adjust them as needed.',
  'Surround yourself with inspiration—quotes, images, or people.',
  'Commit to a healthy lifestyle, not just a quick fix.',
  'Learn something new every day to keep your mind sharp.',
  'Practice forgiveness—let go of grudges to reduce stress.',
  'Keep moving forward, no matter how slow the progress may seem.',
];

const RightSidebar = () => {
  const [currentUserData, setCurrentUserData] = useState({});
  const [healthData, setHealthData] = useState({});
  const [dailyTip, setDailyTip] = useState(dailyTips[0]); // initialize with the first tip
  const [tipIndex, setTipIndex] = useState(0); // keep track of the current tip index
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let userUnsubscribe = () => {};
    let healthUnsubscribe = () => {};

    const setupListeners = async () => {
      if (auth.currentUser && location.pathname !== '/login') {
        userUnsubscribe = onSnapshot(
          db.collection('users').doc(auth.currentUser.uid),
          (doc) => {
            if (doc.exists) {
              setCurrentUserData(doc.data());
            }
          },
          (error) => {
            console.error("Error fetching user data:", error);
          }
        );

        healthUnsubscribe = onSnapshot(
          db.collection('health').doc(auth.currentUser.uid),
          (doc) => {
            if (doc.exists) {
              setHealthData(doc.data());
            }
          },
          (error) => {
            console.error("Error fetching health data:", error);
          }
        );
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setupListeners();
      } else {
        setCurrentUserData({});
        setHealthData({});
      }
    });

    return () => {
      unsubscribe();
      userUnsubscribe();
      healthUnsubscribe();
    };
  }, [location.pathname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTipIndex((tipIndex + 1) % dailyTips.length); // cycle through the tips
      setDailyTip(dailyTips[tipIndex]);
    }, 86400000); // update the tip every 24 hours

    return () => clearInterval(intervalId);
  }, [tipIndex]);

  const isVisible = location.pathname !== '/login' && location.pathname !== '/error';

  // Apply different classes based on the screen size
  const sidebarClasses = isMobile
    ? 'hidden'
    : '"fixed right-0 top-0 mt-0 mr-0 bg-[#2E6C83] text-white p-4 shadow-lg flex-col justify-content-between"';
  return (
    <div className={`${sidebarClasses} ${isVisible ? '' : 'hidden'}`}>
      <div className="flex-1">
        <div className="text-center mb-6">
          <img
            src={currentUserData?.photoURL || DEFAULT_AVATAR_URL}
            alt="User avatar"
            className="w-20 h-20 rounded-full mx-auto mb-2"
          />
          <h2 className="text-xl font-bold">{currentUserData?.displayName || 'User'}</h2>
        </div>
        <div className="space-y-2 text-sm">
          <p>Weight: {healthData?.weight || 'N/A'} kg</p>
          <p>Height: {healthData?.height || 'N/A'} cm</p>
          <p>Steps: {healthData?.steps || 'N/A'}</p>
        </div>
        <div className="mt-4">
          <h3 className="font-bold mb-2">Daily Goals:</h3>
          <p>Calories: {healthData?.dailyCalorieGoal || 'N/A'} kcal</p>
          <p>Steps: {healthData?.dailyStepGoal || 'N/A'}</p>
          <p>Hydration: {healthData?.dailyHydrationGoal || 'N/A'} glasses</p>
          <p>Exercise: {healthData?.dailyExerciseGoal || 'N/A'} minutes</p>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br> 
      {/* i know i shouldnt have done this absolute mess of a code but legit i had no other option and tailwind css did NOT help me */}
    <div className="bg-[#285E72] p-2 rounded-md text-l mt-auto">
    <h3 className="font-bold mb-1 text-sm">Daily Tip:</h3>
    <p>{dailyTip}</p>
      </div>
    </div>
  );
};

export default RightSidebar;
