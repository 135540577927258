import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import MainSidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import YourPlan from './pages/YourPlan';
import MyHealth from './pages/MyHealth';
import Trackers from './components/Trackers';
import Profile from './components/Profile';
import Onboarding from './components/Onboarding';
import Auth from './components/Auth';
import PrivateRoute from './components/PrivateRoute';
import RightSidebar from "./components/right-sidebar";
import Footer from './components/Footer';
import Error from "./pages/error";
import AICenter from './components/center';
import NutriBot from './components/nutribot';
import Mealplanner from './components/meal-planner';
import NotFound from './pages/notfound';

// Custom hook to detect mobile devices
const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
      setIsMobile(mobileRegex.test(userAgent));
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return isMobile;
};

const App = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      window.location.href = 'https://m.dashboard.nutriplan.seif8911.xyz';
    }
  }, [isMobile, navigate]);

  if (isMobile) {
    return null; // Return null while redirecting
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="block d-md-flex w-full h-full">
        <MainSidebar />
        <div className="block d-md-flex overflow-auto bg-gray-200 flex-grow">
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/Error" element={<Error />} />
            {/* Protect routes with PrivateRoute */}
            <Route path="/" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path="/yourplan" element={
              <PrivateRoute>
                <YourPlan />
              </PrivateRoute>
            } />
            <Route path="/myhealth" element={
              <PrivateRoute>
                <MyHealth />
              </PrivateRoute>
            } />
            <Route path="/trackers" element={
              <PrivateRoute>
                <Trackers />
              </PrivateRoute>
            } />
            <Route path="/profile" element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            } />
            <Route path="/center" element={
              <PrivateRoute>
                <AICenter />
              </PrivateRoute>
            } />
            <Route path="/nutribot" element={
              <PrivateRoute>
                <NutriBot />
              </PrivateRoute>
            } />
            <Route path="/meal-planner" element={
              <PrivateRoute>
                <Mealplanner />
              </PrivateRoute>
            } />
            <Route path="*" element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            } />
          </Routes>
        </div>
        <RightSidebar />
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;